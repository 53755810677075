import { useEffect, useState } from "react";
import { DarkModeToggle } from "./components/DarkModeToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub, faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import "./style/style.scss";

function App() {
  const [theme, setTheme] = useState("light");

  const onUpdateTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    window.localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  useEffect(() => {
    const savedTheme = window.localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const jobs = [
    {
      company: "Truminds Software System",
      duration: "Aug 2021 - Present",
      website: "https://www.truminds.com",
      position: "Senior Software Engineer",
    },
    {
      company: "IoT83",
      duration: "Feb 2021 - July 2021",
      website: "https://www.iot83.com/",
      position: "Senior Software Engineer",
    },
    {
      company: "ProfEdge Solutions",
      duration: "June 2019 - Jan 2021",
      website:
        "https://www.linkedin.com/company/profedge-solutions-pvt-ltd-/about/",
      position: "Software Engineer",
    },
    {
      company: "Arohar Technologies",
      duration: "Nov 2018 - April 2019",
      website: "https://www.arohar.com/",
      position: "Software Engineer",
    },
  ];

  const social = [
    {
      name: "LinkedIn",
      iconName: faLinkedin,
      link: "https://www.linkedin.com/in/pradhanadi/",
    },
    {
      name: "Github",
      iconName: faGithub,
      link: "https://github.com/aditya-pradhann",
    },
    {
      name: "Email",
      iconName: faEnvelope,
      link: "mailto:adityaa.pradhan@outlook.com",
    },
  ];

  return (
    <div className="layout" data-theme={theme === "dark" ? "dark" : "light"}>
      {/* Theme Switch Section */}
      <div className="switch-wrapper">
        <DarkModeToggle onUpdateTheme={onUpdateTheme} theme={theme} />
      </div>

      {/* Header Section */}
      <header className="intro">
          <h1 className="intro-hello">Hello <span className="emoji wave-hand animated"></span></h1>
        <h2 className="intro-tagline">
          I'm <span>Aditya Pradhan</span>, a front-end software engineer focused
          on building web applications. <span className='emoji technologist'></span>
        </h2>

        <h3 className="intro-contact">
          <span>Get in touch <span className="emoji finger-pointer"></span> </span>
          <span>
            <a
              href="mailto: adityaa.pradhan@outlook.com"
              target="_blank"
              className="highlight-link"
              rel="noreferrer"
            >
              adityaa.pradhan@outlook.com
            </a>
          </span>
        </h3>
      </header>

      {/* Background Section */}
      <div className="section background">
        <div className="section-title">Background</div>
        <div className="section-content">
          <p>
            I'm currently an Engineer at{" "}
            <a
              href="https://www.truminds.com"
              target="_blank"
              className="underline-link"
              rel="noreferrer"
            >
              Truminds Software Systems
            </a>{" "}
            building things for the web with some awesome people. I did my under
            grad from{" "}
            <a
              href="https://www.gehu.ac.in"
              target="_blank"
              className="underline-link"
              rel="noreferrer"
            >
              Graphic Era Hill University.
            </a>{" "}
            Commencing my professional journey in 2018, I swiftly immersed
            myself in front-end development, utilizing{" "}
            <span className="underline-link">HTML, CSS</span> and{" "}
            <span className="underline-link">JavaScript</span> to craft
            captivating digital experiences.
          </p>

          <p>
            As my journey progressed, I honed my skills in advanced styling
            techniques using <span className="underline-link">SASS/SCSS</span>,
            and proficiently navigated popuplar front-end frameworks like{" "}
            <span className="underline-link">Angular</span> and{" "}
            <span className="underline-link">React</span>. Additionally, I
            leveraged <span className="underline-link">Figma</span> for
            efficient design collaboration and prototyping. Currently, I lead a
            talented team through complex projects, drawing upon my technical
            expertise and leadership capabilities to achieve exceptional
            results.
          </p>
        </div>
      </div>

      {/* Skills */}
      <div className="section skills">
        <div className="section-title">Skills</div>
        <div className="section-content">
          <div className="skill">
            <div className="skill-category">
              <div className="skill-category-label">Languages</div>
              <ul>
                <li className="skill-category-item">JavaScript (ES6)</li>
                <li className="skill-category-item">Typescript</li>
                <li className="skill-category-item">HTML</li>
                <li className="skill-category-item">CSS/SASS</li>
              </ul>
            </div>
            <div className="skill-category">
              <div className="skill-category-label">Frameworks</div>
              <ul>
                <li className="skill-category-item">Angular</li>
                <li className="skill-category-item">React</li>
                <li className="skill-category-item">Highcharts</li>
              </ul>
            </div>
            <div className="skill-category">
              <div className="skill-category-label">Tools</div>
              <ul>
                <li className="skill-category-item">Git & Github</li>
                <li className="skill-category-item">Chrome DevTools</li>
                <li className="skill-category-item">Postman</li>
                <li className="skill-category-item">VS Code</li>
                <li className="skill-category-item">Bash</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Experience */}
      <div className="section experience">
        <div className="section-title">Experience</div>
        <div className="section-content">
          <div className="jobs">
            {jobs.map((value, index) => (
              <div className="job" key={index}>
                <div className="time-place">
                  <div className="job-company">
                    <a href={value.website} target="_blank" rel="noreferrer">
                      {value.company}
                    </a>
                  </div>
                  <div className="job-time">{value.duration}</div>
                </div>
                <div className="job-position">{value.position}</div>
              </div>
            ))}
          </div>
          <a
            href="https://resume.adityapradhan.dev/"
            target="_blank"
            rel="noreferrer"
            className="highlight-link"
          >
            View My Resume
          </a>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-copyright">
          <div className="top">
            <span>Designed & Developed By </span>
          </div>
          <div className="bottom">
            <span> Aditya Pradhan <span className="emoji victory"></span></span>
            <span> {new Date().getFullYear()}</span>
          </div>
        </div>
        <div className="footer-links">
          {social.map((value, index) => (
            <>
              <a key={index} href={value.link} target="_blank" rel="noreferrer">
                <span className="text">{value.name}</span>
                <span>
                  <FontAwesomeIcon icon={value.iconName} size="2xl" />
                </span>
              </a>
            </>
          ))}
        </div>
      </footer>
    </div>
  );
}

export default App;
